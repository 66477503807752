import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App.jsx';
import './index.css';
import store from './utils/state/store.js';




createRoot(document.getElementById('root')).render(
  <StrictMode>
  <Provider store={store}>  
    <App />
  </Provider>
</StrictMode>
);
